@media (min-width: 576px) {
    .main {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .main {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .main {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .main {
        max-width: 1140px;
    }
}


.main {
    width: 100%;
    padding-top: 2rem;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.multi-line-display {
    white-space: pre-line;
}

.margin-0 {
    margin: 0
}