.text-center {
    text-align: center !important;
}

.login-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding-bottom: 80px;
}

.login-container {
    width: 100%;
    max-width: 300px; 
    padding: 15px;
    margin: auto;
}

.border-left {
    border-left: 2px;
}